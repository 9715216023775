import moment from 'moment';

import { endOfToday } from './date';

export function todayCheck(someDate: string): string {
  // someDate will come in format "YYYY-MM-DD"
  const today = endOfToday();
  const date = moment(someDate, 'YYYY-MM-DD');

  return date <= today ? 'today' : someDate;
}
