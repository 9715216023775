import { NgTemplateOutlet } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DialogMessage, SanitizePipe } from '@hae/utils';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'hae-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    TranslateModule,
    NgTemplateOutlet,
    MatButtonModule,
    SanitizePipe,
  ],
})
export class DialogComponent {
  @Input() dataInput?: DialogMessage;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogMessage) {}

  ngOnChanges() {
    if (this.dataInput) {
      this.data = this.dataInput;
    }
  }
}
