import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function noAsteriskValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let validationResult: ValidationErrors | null = null;
    if (control instanceof FormControl) {
      const hasAsterisk = /\*/.test(control.value);
      validationResult = hasAsterisk ? { noAsterisk: true } : null;
    }
    return validationResult;
  };
}
