import { Injectable } from '@angular/core';
import { PrivacyPolicyResponseDto, TenantService, UserService } from '@hae/api';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserAgreementService {
  constructor(
    private tenantService: TenantService,
    private userService: UserService,
  ) {}

  check(moduleName: string): Observable<PrivacyPolicyResponseDto[]> {
    return combineLatest([
      this.getTenantPolicies(moduleName),
      this.getUserAgreements(),
    ]).pipe(
      take(1),
      map((data: [PrivacyPolicyResponseDto[], PrivacyPolicyResponseDto[]]) => {
        const temp: PrivacyPolicyResponseDto[] = [];
        const [tenantAgreementRes, userAgreementRes] = data;
        tenantAgreementRes?.forEach((tenantAgreement) => {
          if (
            userAgreementRes.filter(
              (userAgreement) => userAgreement.id === tenantAgreement.id,
            ).length === 0
          ) {
            temp.push(tenantAgreement);
          }
        });
        return temp;
      }),
    );
  }

  getTenantPolicies(
    moduleName: string,
  ): Observable<PrivacyPolicyResponseDto[]> {
    return this.tenantService
      .getLatestPoliciesForModule(moduleName)
      .pipe(map(({ payload }) => payload.privacyPolicyResponseDtoList));
  }

  signUserAgreement(
    userAgreementId: string,
  ): Observable<PrivacyPolicyResponseDto[]> {
    return this.userService.saveUserAgreements({ createUserAgreementsRequestDtos: [{ policyId: userAgreementId }] });
  }

  private getUserAgreements(): Observable<PrivacyPolicyResponseDto[]> {
    return this.userService.getUserAgreements();
  }
}
