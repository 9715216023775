import { ResourceControllerService } from '@hae/api';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export class CustomTranslateLoader implements TranslateLoader {
  constructor(
    private resourceControllerService: ResourceControllerService,
    private module: string,
  ) {}

  getTranslation(lang: string): Observable<Record<string, string>> {
    return this.resourceControllerService
      .getAllForModuleAndLanguage(this.module, lang)
      .pipe(
        map(({ messages }) => messages),
        catchError(() => of({})),
      );
  }
}
