import { StateToken } from '@ngxs/store';

import { AuthState } from './auth/index.d';
import { I18nState } from './i18n/index.d';
import { PageState } from './page/index.d';
import { TenantState } from './tenant/index.d';

export const AUTH_STATE_TOKEN = new StateToken<AuthState>('auth');
export const TENANT_STATE_TOKEN = new StateToken<TenantState>('tenant');
export const PAGE_STATE_TOKEN = new StateToken<PageState>('page');
export const I18N_STATE_TOKEN = new StateToken<I18nState>('i18n');
export const DEFAULT_LANGUAGE = 'en-US';
