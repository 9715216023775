import { Injectable } from '@angular/core';
import { BecsInfoResponseDto, BecsService } from '@hae/api';
import { TranslateService } from '@ngx-translate/core';
import {
  map, mergeMap, Observable, shareReplay, startWith,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BecsStateService {
  constructor(
    private becsService: BecsService,
    private translateService: TranslateService,
  ) {}

  private becsInfo?: Observable<BecsInfoResponseDto>;

  getBecsInfoHot(): Observable<BecsInfoResponseDto> {
    if (!this.becsInfo) {
      this.becsInfo = this.translateService.onLangChange.pipe(
        startWith({
          lang:
            this.translateService.currentLang
            || this.translateService.defaultLang,
        }),
        mergeMap(({ lang }) => this.becsService.getBecsInfo(lang)),
        map(({ payload }) => payload),
        shareReplay(1),
      );
    }
    return this.becsInfo;
  }
}
