export const URL_PATH_HOME = 'profile';
export const URL_PATH_SETTINGS = 'settings';

export const URL_PATH_PAGE_NOT_FOUND = 'page-not-found';
export const URL_PATH_WELCOME = 'home';
export const URL_PATH_LOGIN = 'login';
export const URL_PATH_USER_ACCOUNT = 'account';
export const URL_PATH_USER_ACCOUNT_LOOKUP = 'lookup';
export const URL_PATH_USER_RETRY_LOOKUP = 'retry-lookup';
export const URL_PATH_USER_ACCOUNT_DONATION_CENTER = 'donation-center';
export const URL_PATH_USER_ACCOUNT_PROFILE_SETUP = 'profile-setup';
export const URL_PATH_QUESTIONNAIRE = 'questionnaire';
export const URL_PATH_QUESTIONNAIRE_ACKNOWLEDGEMENT = 'acknowledgement';
export const URL_PATH_QUESTIONNAIRE_BIOMETRICS_ACKNOWLEDGEMENT = 'biometrics-acknowledgement';
export const URL_PATH_QUESTIONNAIRE_COMPLETE = 'complete';
export const URL_PATH_DONATION = 'donation';
export const URL_PATH_DONATION_SITE = 'site';
export const URL_PATH_DONATION_SITE_TRANSFER = 'transfer';
export const URL_PATH_REFERRAL = 'referral';
