import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';

import { PAGE_STATE_TOKEN } from '../constants';
import { PageState as StateInterface } from './index.d';
import {
  PageLoadedSuccessfully,
  TaskLoadedSuccessfully,
  TaskLoadingStarted,
  UserTransferFinished,
  UserTransferStart,
} from './page.actions';

@State({
  name: PAGE_STATE_TOKEN,
  defaults: { isLoading: false, isUserTransferLoading: false },
})
@Injectable()
export class PageState {
  private loadingTasksCount = 0;

  @Action(PageLoadedSuccessfully)
  pageLoadedSuccessfully({ setState }: StateContext<StateInterface>) {
    this.loadingTasksCount = 0;
    setState(patch<StateInterface>({ isLoading: false }));
  }

  @Action(TaskLoadingStarted)
  taskLoadingStarted({ setState }: StateContext<StateInterface>) {
    this.loadingTasksCount += 1;
    setState(patch<StateInterface>({ isLoading: true }));
  }

  @Action(TaskLoadedSuccessfully)
  taskLoadedSuccessfully({ setState }: StateContext<StateInterface>) {
    this.loadingTasksCount -= 1;
    if (this.loadingTasksCount < 0) {
      this.loadingTasksCount = 0;
    }
    if (this.loadingTasksCount === 0) {
      setState(patch<StateInterface>({ isLoading: false }));
    }
  }

  @Action(UserTransferStart)
  userTransferStart({ setState }: StateContext<StateInterface>): void {
    setState(patch<StateInterface>({ isUserTransferLoading: true }));
  }

  @Action(UserTransferFinished)
  userTransferFinished({ setState }: StateContext<StateInterface>): void {
    setState(
      patch<StateInterface>({ isUserTransferLoading: false, isLoading: false }),
    );
  }
}

export class PageStateSelectors {
  static isLoading = createSelector(
    [PageState],
    (state: StateInterface) => state.isLoading,
  );

  static isUserTransferLoading = createSelector(
    [PageState],
    (state: StateInterface) => state.isUserTransferLoading,
  );
}
