@if (config$ | async; as config) {
  <div class="auth-container">
    <div class="flow-container">
      @switch ($authUiState()) {
        <!--LOGIN FLOW-->
        @case (AuthUIState.LOGIN) {
          <h1>{{ "auth.sign_in_header" | translate }}</h1>
          @if (config.googleLogin === 'true') {
            <hae-google-btn [federatedLogin] = "federatedLogin"/>
          }
          @if (config.facebookLogin === 'true') {
            <hae-facebook-btn [federatedLogin] = "federatedLogin"/>
          }
          @if (config.appleLogin === 'true') {
            <hae-apple-btn [federatedLogin] = "federatedLogin"/>
          }
          @if (config.openIDLogin !== 'false') {
            <button
              (click)="federatedLogin(config.openIDLogin.toString())"
              class="social-login"
              >
              <span class="button-text">{{
                "loginPage.openIDLogin" | translate
              }}</span>
            </button>
          }
          @if (config.SAMLLogin !== 'false') {
            <button
              (click)="federatedLogin(config.SAMLLogin.toString())"
              class="social-login"
              >
              <span class="button-text">{{ "loginPage.SAMLLogin" | translate }}</span>
            </button>
          }
          @if (config.usernameLogin === 'true') {
            @if (
              config.googleLogin === 'true' ||
              config.facebookLogin === 'true' ||
              config.appleLogin === 'true' ||
              config.openIDLogin !== 'false' ||
              config.SAMLLogin !== 'false'
              ) {
              <div
                class="text-with-line"
                >
                <mat-divider></mat-divider>
                <span>{{ "loginPage.or" | translate }}</span>
                <mat-divider></mat-divider>
              </div>
            }
            <ng-content select="[login]"></ng-content>
            <form (ngSubmit)="login()" [formGroup]="loginForm">
              <hae-form-field>
                <mat-label class="label">{{
                  "auth.login_name_label" | translate
                }}</mat-label>
                <input
                  autofocus
                  class="input-amplify"
                  formControlName="username"
                  placeholder="{{ 'auth.login_name' | translate }}"
                  type="email"
                  (focusout)="onFocusOut()"
                  />
                <hae-form-error control="username">{{
                  "login.form.fieldRequired" | translate
                }}</hae-form-error>
              </hae-form-field>
              <hae-form-field>
                <mat-label class="label">{{
                  "auth.password_label" | translate
                }}</mat-label>
                <input
                  class="input-amplify"
                  formControlName="password"
                  placeholder="{{ 'auth.password' | translate }}"
                  type="password"
                  />
                <hae-form-error control="password">{{
                  "login.form.fieldRequired" | translate
                }}</hae-form-error>
              </hae-form-field>
              <div>
                <span class="center"
                  >{{ "auth.forgot_password" | translate }}
                  <span (click)="changeAuthUIState(2)" class="link">{{
                    "auth.reset_password" | translate
                  }}</span>
                </span>
              </div>
              <div>
                <span class="center"
                  >{{ "auth.forgot_email_username" | translate }}
                  <span (click)="forgotEmailUsername()" class="link">{{
                    "auth.reset_email_username" | translate
                  }}</span>
                </span>
              </div>
              <div class="register-container">
                <span
                  >{{ "auth.no_account" | translate }}
                  <span (click)="changeAuthUIState(1)" class="link">{{
                    "auth.create_account" | translate
                  }}</span>
                </span>
                <hae-loading-button
                  [loading]="$loading()"
                  class="login-button"
                  >
                  <button
                    [disabled]="loginForm.invalid"
                    class="accept-button"
                    color="primary"
                    mat-raised-button
                    type="submit"
                    >
                    {{ "auth.sign_in" | translate }}
                  </button>
                </hae-loading-button>
              </div>
            </form>
          }
        }
        <!--REGISTRATION FLOW-->
        @case (AuthUIState.REGISTRATION) {
          <h1>{{ "auth.register_title" | translate }}</h1>
          <form (ngSubmit)="register()" [formGroup]="registrationForm">
            <ng-content select="[register]"></ng-content>
            <hae-form-field>
              <input
                class="input-amplify"
                formControlName="email"
                placeholder="{{ 'auth.email' | translate }}"
                type="email"
                />
              <hae-form-error control="email">{{
                "login.form.fieldRequired" | translate
              }}</hae-form-error>
            </hae-form-field>
            <hae-form-field>
              <input
                class="input-amplify"
                formControlName="username"
                placeholder="{{ 'auth.username' | translate }}"
                />
              <hae-form-error control="username">{{
                "login.form.fieldRequired" | translate
              }}</hae-form-error>
            </hae-form-field>
            <hae-form-field>
              <div class="show-password">
                <input
                  #password
                  class="input-amplify"
                  formControlName="password"
                  placeholder="{{ 'auth.password' | translate }}"
                  type="password"
                  />
                <mat-icon (click)="showPassword(password)">{{
                  password.type === "text" ? "visibility_off" : "visibility"
                }}</mat-icon>
              </div>
              <hae-form-error control="password">{{
                "login.form.fieldRequired" | translate
              }}</hae-form-error>
            </hae-form-field>
            <hae-loading-button [loading]="$loading()">
              <button
                [disabled]="registrationForm.invalid"
                class="accept-button"
                color="primary"
                mat-raised-button
                type="submit"
                >
                {{ "auth.create_account" | translate }}
              </button>
            </hae-loading-button>
          </form>
          <span
            >{{ "auth.have_account" | translate }}
            <span (click)="changeAuthUIState(0)" class="link">{{
              "auth.sign_in" | translate
            }}</span>
          </span>
        }
        <!--EMAIL VERIFICATION-->
        @case (AuthUIState.VERIFY_EMAIL) {
        <hae-code-verification (formData)="verifyCode($event)" [input]="verificationInput" [$loading]="$loading" [deliveryMethod]="'MAIL'"/>
          <div (click)="smsConfirmed()" class="link">{{
            "auth.confirm.by.phone" | translate
          }}
          </div>
          <div (click)="changeAuthUIState(0)" class="link">{{
            "auth.back_to_signin" | translate
          }}
          </div>
        }
        <!--PHONE VERIFICATION-->
        @case (AuthUIState.VERIFY_SMS) {
        <hae-code-verification (formData)="verifyCode($event)" [input]="verificationInput" [$loading]="$loading" [deliveryMethod]="'SMS'"/>
          <span (click)="changeAuthUIState(0)" class="link">{{
            "auth.back_to_signin" | translate
          }}</span>
        }
        <!--FORGOT PASSWORD FLOW-->
        @case (AuthUIState.RESET_PASSWORD) {
          <h1>{{ "auth.forgot_title" | translate }}</h1>
          <form [formGroup]="resetPasswordForm">
            <hae-form-field>
              <input
                class="input-amplify"
                formControlName="email"
                placeholder="{{ 'auth.email' | translate }}"
                type="text"
                (focusout)="onFocusOut()"
                />
              <hae-form-error control="email">{{
                "login.form.fieldRequired" | translate
              }}</hae-form-error>
            </hae-form-field>
            <hae-loading-button [loading]="$loading()">
              <button
                [disabled]="resetPasswordForm.invalid"
                class="accept-button"
                color="primary"
                mat-raised-button
                type="submit"
                (click)="initiatePasswordReset('MAIL')"
                >
                {{ "auth.reset_via_email" | translate }}
              </button>
              <button
                [disabled]="resetPasswordForm.invalid"
                class="accept-button"
                color="primary"
                mat-raised-button
                type="submit"
                (click)="initiatePasswordReset('SMS')"
                >
                {{ "auth.reset_via_sms" | translate }}
              </button>
            </hae-loading-button>
          </form>
          <span (click)="changeAuthUIState(0)" class="link">{{
            "auth.back_to_signin" | translate
          }}</span>
        }
        <!--NEW PASSWORD FLOW-->
        @case (AuthUIState.NEW_PASSWORD) {
          <h1>{{ "auth.forgot_title" | translate }}</h1>
          <div class="description">
            @if(newPasswordForm.getRawValue().deliveryMethod == 'MAIL'){
              {{ "auth.confirm_mail_description" | translate }}
            }@else if (newPasswordForm.getRawValue().deliveryMethod == 'SMS'){
              {{ "auth.confirm_sms_description_password_reset" | translate }}
            }
          </div>
          <form (ngSubmit)="setNewPassword()" [formGroup]="newPasswordForm">
            <hae-form-field>
              <input
                class="input-amplify"
                formControlName="email"
                placeholder="{{ 'auth.email' | translate }}"
                numeric
                />
              <hae-form-error control="email">{{
                "login.form.fieldRequired" | translate
              }}</hae-form-error>
            </hae-form-field>
            <hae-form-field>
              <input
                class="input-amplify"
                formControlName="code"
                placeholder="{{ 'auth.code' | translate }}"
                numeric
                />
              <hae-form-error control="code">{{
                "login.form.fieldRequired" | translate
              }}</hae-form-error>
            </hae-form-field>
            <hae-form-field>
              <div class="show-password">
                <input
                  #newPassword
                  class="input-amplify"
                  formControlName="newPassword"
                  placeholder="{{ 'auth.new_password' | translate }}"
                  type="password"
                  />
                <mat-icon (click)="showPassword(newPassword)">{{
                  newPassword.type === "text" ? "visibility_off" : "visibility"
                }}</mat-icon>
              </div>
              <hae-form-error control="newPassword"
                >{{ "login.form.fieldRequired" | translate }}
              </hae-form-error>
            </hae-form-field>
            <hae-loading-button [loading]="$loading()">
              <button
                [disabled]="newPasswordForm.invalid"
                class="accept-button"
                color="primary"
                mat-raised-button
                type="submit"
                >
                {{ "auth.submit" | translate }}
              </button>
            </hae-loading-button>
          </form>
          <div>
            <span (click)="reinitiatePasswordReset()" class="link">{{
              "auth.resend_code" | translate
            }}</span>
          </div>
          @if(newPasswordForm.getRawValue().deliveryMethod == 'SMS'){
            <div>
              <span (click)="showUpdatePhonePopUp()" class="link">{{
                "auth.update.phone.forgot.password" | translate
              }}</span>
            </div>
          }
          <div>
            <span (click)="changeAuthUIState(0)" class="link">{{
              "auth.back_to_signin" | translate
            }}</span>
          </div>
        }@case (AuthUIState.CONFIRM_SMS) {
          <h1>{{ "auth.confirm_sms.title" | translate }}</h1>
          <div class="description">
            {{ "auth.confirm_sms.text" | translate }}
          </div>
          <form (ngSubmit)="startSmsVerification()" [formGroup]="confirmSmsForm">
            <hae-form-field>
              <input
                class="input-amplify"
                formControlName="phone"
                placeholder="{{ 'auth.phone' | translate }}"
                type="text"
                />
              <hae-form-error control="phone">{{
                "login.form.fieldRequired" | translate
              }}</hae-form-error>
            </hae-form-field>
            <hae-loading-button [loading]="$loading()">
              <button
                class="accept-button"
                color="primary"
                mat-raised-button
                type="submit"
                >
                {{ "auth.send_code" | translate }}
              </button>
            </hae-loading-button>
          </form>
          <div (click)="changeAuthUIState(0)" class="link">{{
            "auth.back_to_signin" | translate
          }}</div>
          <div (click)="showUpdatePhonePopUp()" class="link">{{
            "auth.update.phone" | translate
          }}</div>
        }
        @case (AuthUIState.CODE_VERIFICATION) {
          <h1>{{ "auth.confirm_signup" | translate }}</h1>
          <div class="description">
            {{ "auth.confirm_text1" | translate }}
            {{ "auth.confirm_text2" | translate }}
          </div>
          <form (ngSubmit)="verifyCode(this.codeVerificationForm.getRawValue())" [formGroup]="codeVerificationForm">
            <hae-form-field>
              <input
                class="input-amplify"
                formControlName="input"
                placeholder="{{ 'auth.email' | translate }}"
                type="text"
                />
              <hae-form-error control="input">{{
                "login.form.fieldRequired" | translate
              }}</hae-form-error>
            </hae-form-field>
            <hae-form-field>
              <input
                class="input-amplify"
                formControlName="code"
                placeholder="{{ 'auth.confirmation_code' | translate }}"
                numeric
                />
              <hae-form-error control="code">{{
                "login.form.fieldRequired" | translate
              }}</hae-form-error>
            </hae-form-field>
            <span>
              {{ "auth.lost_code" | translate }}
              <span (click)="resendCognitoCode()" class="link">{{
                "auth.resend_code" | translate
              }}</span>
            </span>
            <hae-loading-button [loading]="$loading()">
              <button
                [disabled]="codeVerificationForm.invalid"
                class="accept-button"
                color="primary"
                mat-raised-button
                type="submit"
                >
                {{ "auth.send_code" | translate }}
              </button>
            </hae-loading-button>
          </form>
          <span (click)="changeAuthUIState(0)" class="link">{{
            "auth.back_to_signin" | translate
          }}</span>
        }
      }
    </div>
  </div>
}
