import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TenantStateService } from '@hae/state';
import {
  DialogComponent,
  EMERGENCY_DISMISSED,
  MetaService,
  UserAgreementService,
} from '@hae/utils';
import { Action, State } from '@ngxs/store';

import { TENANT_STATE_TOKEN } from '../constants';
import { ShowTenantEmergency } from './tenant.actions';

@State({
  name: TENANT_STATE_TOKEN,
  defaults: { tenantNotAcceptedPolicies: [] },
})
@Injectable()
export class TenantState {
  constructor(
    private dialog: MatDialog,
    private metaService: MetaService,
    private ngZone: NgZone,
    private userAgreementService: UserAgreementService,
    private tenantStateService: TenantStateService,
  ) {}

  @Action(ShowTenantEmergency)
  showTenantEmergency() {
    this.tenantStateService
      .getConfiguration()
      .subscribe(({ emergencyMessage }) => {
        if (
          emergencyMessage
          && this.metaService.get(EMERGENCY_DISMISSED) !== emergencyMessage
        ) {
          this.ngZone.run(() => this.dialog.open(DialogComponent, {
            data: {
              title: 'dialog.emergency.title',
              text: emergencyMessage,
              secondaryLabel: 'dialog.dismiss',
              secondaryCallback: () => this.metaService.set(EMERGENCY_DISMISSED, emergencyMessage),
            },
          }));
        }
      });
  }
}
