import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Renderer2,
} from '@angular/core';
import { TenantStateService } from '@hae/state';

declare global{
  interface Window {dataLayer: {'gtm.start': number; event:string}[]}
}

@Component({
  selector: 'hae-google-tag',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class GoogleTagComponent implements OnInit {
  constructor(
    private renderer: Renderer2,
    private tenantStateService: TenantStateService,
  ) {}

  ngOnInit(): void {
    this.tenantStateService
      .getConfiguration()
      .subscribe(({ googleAnalyticsContainerId }) => {
        const googleAnalyticsContainerIdEscaped = googleAnalyticsContainerId.replaceAll('<', '&#60;')
          .replaceAll('>', '&#62;').replaceAll("'", '&#39;'); // the other malicious characters are already being escaped.
        const head = document.querySelector('head');
        const scriptElement = this.renderer.createElement('script');
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'gtm.start':
            new Date().getTime(),
          event: 'gtm.js',
        });
        scriptElement.id = 'googleAnalyticsId'; // added for testing
        scriptElement.async = true;
        scriptElement.src = `https://www.googletagmanager.com/gtm.js?id=${googleAnalyticsContainerIdEscaped}`;
        this.renderer.appendChild(head, scriptElement);
      });
  }
}
