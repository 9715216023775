import { Injectable } from '@angular/core';
import { AuthService } from '@hae/api';
import { CognitoService, PoolService } from '@hae/auth';
import { map, mergeMap, Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TokenTransferService {
  constructor(
    private poolService: PoolService,
    private cognitoService: CognitoService,
    private authService: AuthService,
  ) {}

  getTransferId(): Observable<undefined | string> {
    return this.poolService.getSession().pipe(
      map((session) => {
        if (!session) {
          return undefined;
        }

        return {
          accessToken: session.getAccessToken().getJwtToken(),
          idToken: session.getIdToken().getJwtToken(),
          refreshToken: session.getRefreshToken().getToken(),
        };
      }),
      mergeMap((params) => {
        if (!params) {
          return of(undefined);
        }

        return this.authService
          .storeToken1(params)
          .pipe(map(({ payload }) => payload.id));
      }),
    );
  }

  useTransferId(id: string): Observable<boolean> {
    return this.authService.getStoredTokenSet(id).pipe(
      mergeMap(({ payload }) => {
        if (!payload) {
          return of(false);
        }

        return this.poolService.getSession().pipe(
          mergeMap((session) => {
            if (
              // Not logged in
              !session
              // We are on the same session as before
              || session.getRefreshToken().getToken() === payload.refreshToken
            ) {
              // Don't logout
              return of(undefined);
            }
            return this.cognitoService.logout();
          }),
          mergeMap(() => this.cognitoService.authenticateSSO({
            AccessToken: payload.accessToken,
            RefreshToken: payload.refreshToken,
            IdToken: payload.idToken,
          })),
          mergeMap(() => this.cognitoService.isLoggedIn()),
        );
      }),
    );
  }
}
