import type { Moment } from 'moment';
import moment from 'moment';

export function endOfToday(): Moment {
  return moment().endOf('day');
}

export const dateSettings = {
  maxDate: new Date(),
  startDate: new Date(1990, 0, 1),
  minDate: new Date(1901, 0, 1),
};
