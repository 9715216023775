import {
  Component, EventEmitter, Input, OnInit, Output,
  WritableSignal,
} from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ConfirmationControllerService } from '@hae/api';
import { DialogComponent, FormErrorComponent, FormFieldComponent, LoadingButtonComponent } from '@hae/utils';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'hae-code-verification',
  templateUrl: './code-verification.component.html',
  styleUrls: ['../auth/auth.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    LoadingButtonComponent,
    FormFieldComponent,
    MatFormFieldModule,
    FormErrorComponent,
  ],
})
export class CodeVerificationComponent implements OnInit {
  @Input() $loading! :WritableSignal<boolean>;

  @Input() input! :string;

  @Input() deliveryMethod!: 'MAIL' | 'SMS';

  @Output() formData = new EventEmitter<{deliveryMethod:'MAIL'|'SMS', code:string}>();

  public codeVerificationForm = this.fb.group({
    input: [
      {
        value: this.input,
        disabled: true,
      },
      [Validators.required],
    ],
    code: ['', [Validators.required]],
  });

  constructor(
    private fb: NonNullableFormBuilder,
    private confirmationControllerService: ConfirmationControllerService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.codeVerificationForm.setValue({ input: this.input, code: '' });
  }

  resendVerificationCode() {
    this.$loading.set(true);
    this.confirmationControllerService.initiateConfirmationForType(this.deliveryMethod).pipe(
      catchError(() => {
        this.dialog.open(DialogComponent, {
          data: {
            title: 'dialog.verificationCode.notSent.title',
            text: 'dialog.verificationCode.notSent.text',
          },
        });
        this.$loading.set(false);
        return of();
      }),
    ).subscribe(() => {
      this.dialog.open(DialogComponent, {
        data: {
          title: 'dialog.verificationCodeResent.title',
          text: 'dialog.verificationCodeResent.text',
        },
      });
      this.$loading.set(false);
    });
  }

  emitData() {
    const { code } = this.codeVerificationForm.getRawValue();
    this.formData.emit({
      deliveryMethod: this.deliveryMethod,
      code,
    });
  }
}
