import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'hae-facebook-btn',
  templateUrl: './facebook-button.component.html',
  styleUrls: ['../auth/auth.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
  ],
})
export class FacebookBtnComponent {
  @Input() public federatedLogin!: (loginProvider: string) => void;
}
