<button
  (click)="federatedLogin('SignInWithApple')"
  class="social-login apple"
  >
  <span class="social-icon">
    <svg
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
      >
      <path
        d="M21.8206 8.55371C19.3006 8.55371 18.2356 9.75621 16.4806 9.75621C14.6812 9.75621 13.3087 8.56246 11.125 8.56246C8.98749 8.56246 6.70812 9.86746 5.26062 12.0906C3.22812 15.2256 3.57312 21.13 6.86499 26.16C8.04249 27.9606 9.61499 29.98 11.6775 30.0018H11.715C13.5075 30.0018 14.04 28.8281 16.5069 28.8143H16.5444C18.9744 28.8143 19.4619 29.995 21.2469 29.995H21.2844C23.3469 29.9731 25.0037 27.7356 26.1812 25.9418C27.0287 24.6518 27.3437 24.0043 27.9937 22.545C23.2319 20.7375 22.4669 13.9868 27.1762 11.3987C25.7387 9.59871 23.7187 8.55621 21.8144 8.55621L21.8206 8.55371Z"
        fill="white"
        />
      <path
        d="M21.2656 2C19.7656 2.10187 18.0156 3.05687 16.9906 4.30375C16.0606 5.43375 15.2956 7.11 15.5956 8.73563H15.7156C17.3131 8.73563 18.9481 7.77375 19.9031 6.54125C20.8231 5.36813 21.5206 3.70563 21.2656 2Z"
        fill="white"
        />
    </svg>
  </span>
  <span class="button-text">{{
    "auth.federated.apple" | translate
  }}</span>
</button>
