import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class SanitizerService {
  constructor(private sanitizer: DomSanitizer) {}

  getUrlFromBlob(blob: Blob): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      URL.createObjectURL(blob),
    );
  }
}
