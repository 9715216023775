import { Injectable } from '@angular/core';
import { ResourceControllerService } from '@hae/api';
import { Observable, shareReplay, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ResourceStateService {
  private logo?: Observable<Blob>;

  private watermark?: Observable<Blob>;

  constructor(private resourceControllerService: ResourceControllerService) {}

  getLogo(): Observable<Blob> {
    if (!this.logo) {
      this.logo = this.resourceControllerService
        .getMediaAsBlob('LOGO')
        .pipe(shareReplay(1), take(1));
    }
    return this.logo;
  }

  getWatermark(): Observable<Blob> {
    if (!this.watermark) {
      this.watermark = this.resourceControllerService
        .getMediaAsBlob('WATERMARK')
        .pipe(shareReplay(1), take(1));
    }
    return this.watermark;
  }
}
