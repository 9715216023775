import { Injectable } from '@angular/core';
import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import {
  BehaviorSubject, first, map, mergeMap, Observable, of,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PoolService {
  private userPool = new BehaviorSubject<CognitoUserPool | undefined>(
    undefined,
  );

  setUserPool(userPool: CognitoUserPool): void {
    this.userPool.next(userPool);
  }

  getUserPool(): Observable<CognitoUserPool> {
    return this.userPool.pipe(
      first((userPool): userPool is CognitoUserPool => Boolean(userPool)),
    );
  }

  getCurrentUser(): Observable<CognitoUser | null> {
    return this.getUserPool().pipe(
      map((userPool) => userPool.getCurrentUser()),
    );
  }

  getSession(): Observable<CognitoUserSession | null> {
    return this.getCurrentUser().pipe(
      mergeMap((currentUser) => {
        if (!currentUser) {
          return of(null);
        }

        return new Observable<CognitoUserSession | null>((subscriber) => {
          // If the session is expired, it automatically renews using refresh token
          currentUser.getSession(
            (_: null, session: CognitoUserSession | null) => {
              if (session?.isValid()) {
                subscriber.next(session);
              } else {
                subscriber.next(null);
              }
              subscriber.complete();
            },
          );
        });
      }),
    );
  }
}
