import { Injectable, Injector } from '@angular/core';
import { BASE_PATH, DonorIdentityAuthClientResponseDto } from '@hae/api';
import { TenantStateService } from '@hae/state';
import { OAuthService } from 'angular-oauth2-oidc';
import { tap } from 'rxjs';

import { CognitoService } from '../cognito/cognito.service';

@Injectable({ providedIn: 'root' })
export abstract class OAuthAbstractService {
  protected oauthService: OAuthService;

  protected basePath: string;

  private cognitoService: CognitoService;

  private ssoAction?: () => void;

  constructor(injector: Injector) {
    this.oauthService = injector.get(OAuthService);
    this.basePath = injector.get(BASE_PATH);
    this.cognitoService = injector.get(CognitoService);

    const tenantStateService = injector.get(TenantStateService);
    tenantStateService.getAuth().subscribe((auth) => {
      this.init(auth);
    });
  }

  setSSOAction(action: () => void): void {
    this.ssoAction = action;
  }

  login(identityProvider: string): void {
    this.oauthService.initCodeFlow(undefined, { identity_provider: identityProvider });
  }

  protected abstract init(auth: DonorIdentityAuthClientResponseDto): void;

  protected getConfig({
    authUrl,
    clients,
    userPoolId,
  }: DonorIdentityAuthClientResponseDto) {
    return {
      issuer: `https://cognito-idp.us-east-2.amazonaws.com/${userPoolId}`,
      clientId: Object.keys(clients)[0],
      responseType: 'code',
      redirectUri: `${this.basePath}/login`,
      scope: 'profile openid email aws.cognito.signin.user.admin',
      tokenEndpoint: `${authUrl}/oauth2/token`,
      loginUrl: `${authUrl}/oauth2/authorize`,
      logoutUrl: `${this.basePath}/login`,
      userinfoEndpoint: `${authUrl}/oauth2/userInfo`,
    };
  }

  protected doSSO(): void {
    if (this.oauthService.hasValidAccessToken()) {
      this.cognitoService
        .authenticateSSO({
          AccessToken: this.oauthService.getAccessToken(),
          RefreshToken: this.oauthService.getRefreshToken(),
          IdToken: this.oauthService.getIdToken(),
        })
        .pipe(tap(() => this.oauthService.logOut(true)))
        .subscribe(() => {
          if (this.ssoAction) {
            this.ssoAction();
          }
        });
    }
  }
}
