import { Injectable } from '@angular/core';
import { CognitoService } from '@hae/auth';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private cognitoService: CognitoService) {}

  isUserLogged(): Observable<boolean> {
    return this.cognitoService.isLoggedIn();
  }

  getAuthenticatedUserAttributes(): Observable<CognitoUserAttribute[]> {
    return this.cognitoService.getUserAttributes();
  }

  logoutUser(global?: boolean): Observable<void> {
    return this.cognitoService.logout(global).pipe(
      catchError((error) => {
        if (error?.code === 'NotAuthorizedException') {
          return of(undefined);
        }
        throw error;
      }),
    );
  }
}
