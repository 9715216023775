import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment, { Moment } from 'moment';

export function noDateBeforeMinDate(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      return moment(control.value).isBefore(new Date(1901, 0, 1))
        ? { isBefore: true }
        : null;
    }
    return null;
  };
}

export function noDateBeforeDate(date: Moment): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      return moment(control.value).isBefore(date) ? { isBefore: true } : null;
    }
    return null;
  };
}
