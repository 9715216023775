import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InformationControllerService } from '@hae/api';
import { TokenTransferService } from '@hae/auth';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NewDonorUrlService {
  constructor(
    private tokenTransferService: TokenTransferService,
    private informationControllerService: InformationControllerService,
    private translateService: TranslateService,
  ) {}

  openNewDonorUrl(additionalParams?: object) {
    return forkJoin([
      this.informationControllerService.getCurrentTenant(),
      this.tokenTransferService.getTransferId(),
    ]).pipe(
      map(([{ id: tenantId }, token]) => {
        let params: Record<string, string> = {
          tenantId,
          ...additionalParams,
          lang: this.translateService.currentLang,
        };

        // Clean up all empty parameters
        params = Object.keys(params).reduce((acc, key) => {
          if (params[key]) {
            acc[key] = params[key];
          }
          return acc;
        }, {} as Record<string, string>);

        let httpParams = new HttpParams({ fromObject: params });

        if (token) {
          httpParams = httpParams.append('transferToken', token);
        }

        const query = httpParams.toString();
        return `${this.translateService.instant('newDonor.url')}?${query}`;
      }),
    );
  }
}
