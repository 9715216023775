import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'sanitize', standalone: true })
export class SanitizePipe implements PipeTransform {
  constructor(private domSanitization: DomSanitizer) {
  }

  transform(translation: string): string | null {
    return this.domSanitization.sanitize(SecurityContext.HTML, translation);
  }
}
