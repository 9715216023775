import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationControllerService, DonorControllerService, ProfileResponseDto, UserService } from '@hae/api';
import { DialogComponent } from '@hae/utils';
import {
  catchError,
  mergeMap,
  Observable,
  of,
  Subject,
  takeUntil,
} from 'rxjs';

export enum ConfirmationStatus{
  SMS,
  MAIL,
  CONFIRMED,
  NOT_A_DONOR,
  ERROR
}
export type ConfirmationData= {
  status: ConfirmationStatus,
  error?: string
}
export enum SentCodeStatus{
  CONFIRMED,
  ERROR_DURING_SEND,
  NO_STATUS,
  NOT_A_DONOR,
  STARTED_CONFIRMATION_MAIL,
  STARTED_CONFIRMATION_SMS

}

export type SendCodeData = {
  sentStatus:SentCodeStatus;
  phone?:string;
  email?:string;
}
@Injectable({ providedIn: 'root' })
export class MfaService {
  $end = new Subject();

  skipEmailVerification = signal(false);

  constructor(
    private donorControllerService: DonorControllerService,
    private confirmationControllerService: ConfirmationControllerService,
    private userService: UserService,
    private dialog: MatDialog,
  ) {}

  private sendCode(user:ProfileResponseDto): Observable<ConfirmationData> {
    if (user?.userType === ProfileResponseDto.UserTypeEnum.Donor) {
      if ((!user.confirmationsResponse.emailConfirmed || !user.confirmationsResponse.smsConfirmed) && !(this.skipEmailVerification() && user.confirmationsResponse.smsConfirmed)) {
        return this.confirmationControllerService.initiateConfirmationForType(!user.confirmationsResponse.emailConfirmed && !this.skipEmailVerification() ? 'MAIL' : 'SMS').pipe(
          mergeMap(
            () => of({ status: !user.confirmationsResponse.emailConfirmed && !this.skipEmailVerification() ? ConfirmationStatus.MAIL : ConfirmationStatus.SMS } as ConfirmationData),
          ),
          catchError((err) => of({ status: !user.confirmationsResponse.emailConfirmed ? ConfirmationStatus.MAIL : ConfirmationStatus.SMS, error: err?.error?.error?.code as string } as ConfirmationData)),
        );
      }
    } else if (user?.userType === ProfileResponseDto.UserTypeEnum.New) {
      return of({ status: ConfirmationStatus.NOT_A_DONOR } as ConfirmationData);
    }
    return of({ status: ConfirmationStatus.CONFIRMED } as ConfirmationData);
  }

  startConfirmation() {
    return this.donorControllerService.getProfile().pipe(mergeMap((user) => {
      if (user?.userType === ProfileResponseDto.UserTypeEnum.Donor) {
        return this.donorControllerService.getLookupDonor().pipe(
          takeUntil(this.$end),
          mergeMap(
            (lookup) => {
              const phone = lookup?.phoneNumber || '';
              return this.sendCode(user).pipe(
                takeUntil(this.$end),
                mergeMap(
                  (confirmationStatus) => {
                    if (confirmationStatus) {
                      if (confirmationStatus.error) {
                        if (confirmationStatus.error === 'Not a donor user') {
                          this.$end.next('');
                          return of({ sentStatus: SentCodeStatus.NOT_A_DONOR } as SendCodeData);
                        }
                        this.dialog.open(DialogComponent, {
                          data: {
                            title: 'dialog.confirmation.error.title',
                            text: 'dialog.confirmation.error.text',
                          },
                        });
                        return of({ sentStatus: SentCodeStatus.ERROR_DURING_SEND } as SendCodeData);
                      }
                      if (confirmationStatus.status === ConfirmationStatus.MAIL) {
                        return of({ email: user.email, sentStatus: SentCodeStatus.STARTED_CONFIRMATION_MAIL } as SendCodeData);
                      } if (confirmationStatus.status === ConfirmationStatus.SMS) {
                        return of({ phone, sentStatus: SentCodeStatus.STARTED_CONFIRMATION_SMS } as SendCodeData);
                      } if (confirmationStatus.status === ConfirmationStatus.CONFIRMED) {
                        this.$end.next('');
                        return of({ sentStatus: SentCodeStatus.CONFIRMED } as SendCodeData);
                      }
                    }
                    return of({ sentStatus: SentCodeStatus.NO_STATUS } as SendCodeData);
                  },
                ),
              );
            },
          ),
        );
      }
      return of({ sentStatus: SentCodeStatus.CONFIRMED } as SendCodeData);
    }));
  }

  confirmCode(code:string, deliveryMethod: 'MAIL'|'SMS') {
    return this.confirmationControllerService.confirmCode(deliveryMethod, code).pipe(
      catchError((errorResponse: HttpErrorResponse) => of(errorResponse?.error?.error?.code || 'error')),
      mergeMap(
        (response) => {
          if (response === 'Code confirmed') {
          // success either phone or email has been verified
            return of({ status: ConfirmationStatus.CONFIRMED } as ConfirmationData);
          }
          return of({ status: ConfirmationStatus.ERROR, error: response } as ConfirmationData);
        },
      ),
    );
  }

  getPhone() {
    return this.donorControllerService.getLookupDonor().pipe(
      mergeMap((lookup) => {
        if (lookup) {
          return of(lookup.phoneNumber);
        }
        return of('N/A');
      }),
    );
  }

  sendCodeSmsNoVerification() {
    // do not check wether the user is already verified
    return this.donorControllerService.getProfile().pipe(
      mergeMap(
        (user) => {
          if (user.userType === ProfileResponseDto.UserTypeEnum.Donor) {
            return this.confirmationControllerService.initiateConfirmationForType('SMS').pipe(
              mergeMap(
                () => of({ status: ConfirmationStatus.SMS } as ConfirmationData),
              ),
              catchError((err) => of({ status: ConfirmationStatus.SMS, error: err.error?.error?.code as string } as ConfirmationData)),
            );
          }
          return of({ status: ConfirmationStatus.SMS, error: 'Not linked to a donor' } as ConfirmationData);
        },
      ),
    );
  }

  isUserConfirmed() {
    return this.donorControllerService.getProfile().pipe(
      mergeMap(
        (user) => {
          if (user.confirmationsResponse?.smsConfirmed && user.confirmationsResponse?.emailConfirmed) {
            return of(true);
          } if (this.skipEmailVerification() && user.confirmationsResponse?.smsConfirmed) {
            return of(true);
          }
          return of(false);
        },
      ),
    );
  }

  verifyPasswordReset(confirmationCodeId:string, code:string, newPassword: string) {
    return this.confirmationControllerService.verifyPasswordReset(confirmationCodeId, code).pipe(
      mergeMap(
        (res) => this.userService.changePassword(res.userId, res.authenticationTicket, newPassword),
      ),
    );
  }
}
