import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'hae-form-error',
  templateUrl: './form-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatFormFieldModule, AsyncPipe],
})
export class FormErrorComponent {
  @Input() control!: string;

  @Input() error = 'required';

  showError$ = new BehaviorSubject(false);

  private destroyRef = inject(DestroyRef);

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    const form = <FormGroup> this.controlContainer.control;
    const control = <FormControl>form.get(this.control);
    const originalMethod = control.markAsTouched;

    control.markAsTouched = (...args) => {
      originalMethod.apply(control, args);
      this.checkForError(control);
    };

    control.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.checkForError(control);
      });
  }

  private checkForError(control: FormControl) {
    const { value } = control;
    const hasValue = (value === 0 || value) && (!Array.isArray(value) || value.length);

    this.showError$.next(
      control.hasError(this.error) && (control.touched || hasValue),
    );
  }
}
