import {
  Injector,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { ResourceControllerService } from '@hae/api';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';

import { CustomTranslateLoader } from './services/custom-translate.loader';

const DEFAULT_LANGUAGE = 'en-US';

const moduleName = '';

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [ResourceControllerService, moduleName],
      },
    }),
  ],
})
export class I18nModule {
  static forRoot(name: string): ModuleWithProviders<I18nModule> {
    return {
      ngModule: I18nModule,
      providers: [{ provide: moduleName, useValue: name }],
    };
  }

  constructor(
    injector: Injector,
    @Optional() @SkipSelf() parentModule?: I18nModule,
  ) {
    if (parentModule) {
      throw new Error('I18nModule is already loaded.');
    }
    const translate = injector.get(TranslateService);

    translate.setDefaultLang(DEFAULT_LANGUAGE);
    translate.use(DEFAULT_LANGUAGE);

    try {
      const dateAdapter = injector.get(DateAdapter);
      translate.onLangChange.subscribe(({ lang }) => {
        dateAdapter.setLocale(lang);
      });
      // eslint-disable-next-line no-empty
    } catch {}
  }
}
