import { AsyncPipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { SanitizePipe } from '@hae/utils';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { AppleBtnComponent } from '../apple-button/apple-button.component';
import { FacebookBtnComponent } from '../facebook-button/facebook-button.component';
import { GoogleBtnComponent } from '../google-button/google-button.component';

export type OauthDialogData = {
    federatedLogin: (loginProvider:string) => void,
    provider: string,
    tenantConfig:Observable<Record<string, string>>
}
@Component({
  selector: 'hae-oauth-dialog',
  templateUrl: './oauth-dialog.component.html',
  styleUrls: ['./oauth-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    TranslateModule,
    GoogleBtnComponent,
    FacebookBtnComponent,
    AppleBtnComponent,
    SanitizePipe,
    MatButtonModule,
    AsyncPipe,
  ],
})
export class OauthDialogComponent {
  public provider?: string;

  public federatedLogin!: (loginProvider: string) => void;

  public tenantConfig$!: Observable<Record<string, string>>;

  constructor(@Inject(MAT_DIALOG_DATA) public data:OauthDialogData, public dialogRef:MatDialogRef<OauthDialogComponent>) {
    this.provider = data.provider;
    this.federatedLogin = data.federatedLogin;
    this.tenantConfig$ = data.tenantConfig;
  }

  close() {
    this.dialogRef.close();
  }
}
