import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ResourceControllerService } from '@hae/api';

@Component({
  selector: 'hae-backend-style',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class BackendStyleComponent implements OnInit {
  @Input() name = '';

  constructor(
    private renderer: Renderer2,
    private resourceControllerService: ResourceControllerService,
  ) {}

  ngOnInit(): void {
    this.resourceControllerService
      .getMediaAsText(this.name)
      .subscribe((style) => {
        const head = document.querySelector('head');

        let newStyle = style;
        if (
          this.name === 'CSS_STYLES_SCANNER'
          && style.includes('--donor360-accent-color')
        ) {
          style.split('\n').some((line) => {
            const result = line.includes('--donor360-accent-color');
            if (result) {
              const [, value] = line.split(':');
              const match = value.match(
                /rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/i,
              );
              if (match) {
                newStyle += `:root{--palette-primary-500:${match[1]},${match[2]},${match[3]}}`;
              }
            }
            return result;
          });
        }

        const styleElement: HTMLStyleElement = this.renderer.createElement('style');
        styleElement.innerHTML = newStyle;
        this.renderer.appendChild(head, styleElement);
      });
  }
}
