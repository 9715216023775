import { Injectable } from '@angular/core';
import { DonorIdentityAuthClientResponseDto } from '@hae/api';
import { TenantStateService } from '@hae/state';
import { RegistrationForm } from '@hae/utils';
import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import {
  filter, mergeMap, Observable, of, Subscriber, throwError,
} from 'rxjs';
import { map } from 'rxjs/operators';

import { PoolService } from '../pool/pool.service';

@Injectable({ providedIn: 'root' })
export class CognitoService {
  constructor(
    private tenantStateService: TenantStateService,
    private poolService: PoolService,
  ) {
    this.init();
  }

  init(): void {
    this.tenantStateService.getAuth().subscribe((auth) => {
      this.configure(auth);
    });
  }

  getUserAttributes(): Observable<CognitoUserAttribute[]> {
    return this.poolService.getCurrentUser().pipe(
      mergeMap((currentUser) => {
        if (!currentUser) {
          return throwError(() => new Error('Not logged in'));
        }

        return new Observable<CognitoUserAttribute[]>((subscriber) => {
          currentUser.getSession(() => {
            currentUser.getUserAttributes(
              (err?: Error, attributes?: CognitoUserAttribute[]) => {
                if (err) {
                  subscriber.error(err);
                } else if (!attributes) {
                  subscriber.error(new Error('No user attributes found'));
                } else {
                  subscriber.next(attributes);
                }
                subscriber.complete();
              },
            );
          });
        });
      }),
    );
  }

  authenticate(
    username: string,
    password: string,
  ): Observable<CognitoUserSession> {
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    return this.getCognitoUser(username).pipe(
      mergeMap(
        (user) => new Observable<CognitoUserSession>((subscriber) => user.authenticateUser(authenticationDetails, {
          onSuccess: (result) => {
            subscriber.next(result);
            subscriber.complete();
          },
          onFailure: (err: Error) => {
            subscriber.error(err);
            subscriber.complete();
          },
        })),
      ),
    );
  }

  authenticateSSO(authResult: {
    AccessToken: string;
    RefreshToken: string;
    IdToken: string;
  }): Observable<void> {
    const idToken = new CognitoIdToken(authResult);
    const accessToken = new CognitoAccessToken(authResult);
    const refreshToken = new CognitoRefreshToken(authResult);

    return this.getCognitoUser(accessToken.payload.username).pipe(
      mergeMap((user) => {
        user.setSignInUserSession(
          new CognitoUserSession({
            IdToken: idToken,
            AccessToken: accessToken,
            RefreshToken: refreshToken,
          }),
        );
        return of(undefined);
      }),
    );
  }

  refreshSession(): Observable<void> {
    return this.poolService.getCurrentUser().pipe(
      mergeMap((currentUser) => {
        if (!currentUser) {
          return throwError(() => new Error('Not logged in'));
        }

        return this.poolService.getSession().pipe(
          filter((session): session is CognitoUserSession => Boolean(session)),
          mergeMap(
            (currentSession) => new Observable<void>((subscriber) => {
              currentUser.refreshSession(
                currentSession.getRefreshToken(),
                this.errorCallback(subscriber),
              );
            }),
          ),
        );
      }),
    );
  }

  registerUser(registerForm: RegistrationForm): Observable<void> {
    return this.poolService.getUserPool().pipe(
      mergeMap(
        (pool) => new Observable<void>((subscriber) => {
          pool.signUp(
            registerForm.email,
            registerForm.password,
            [...(registerForm.customAttributes ?? [])],
            [],
            this.errorCallback(subscriber),
          );
        }),
      ),
    );
  }

  confirmRegistration(
    username: string,
    verificationCode: string,
  ): Observable<void> {
    return this.getCognitoUser(username).pipe(
      mergeMap(
        (user) => new Observable<void>((subscriber) => {
          user.confirmRegistration(
            verificationCode,
            true,
            this.errorCallback(subscriber),
          );
        }),
      ),
    );
  }

  resendCode(username: string): Observable<void> {
    return this.getCognitoUser(username).pipe(
      mergeMap(
        (user) => new Observable<void>((subscriber) => {
          user.resendConfirmationCode(this.errorCallback(subscriber));
        }),
      ),
    );
  }

  logout(global?: boolean): Observable<void> {
    return this.poolService.getCurrentUser().pipe(
      mergeMap((currentUser) => {
        if (!currentUser) {
          return of(undefined);
        }

        return new Observable<void>((subscriber) => {
          const callback = () => {
            subscriber.next();
            subscriber.complete();
          };

          currentUser.getSession(() => {
            if (global) {
              currentUser.globalSignOut({
                onSuccess: callback,
                onFailure: (err: Error) => {
                  subscriber.error(err);
                  subscriber.complete();
                },
              });
            } else {
              currentUser.signOut(callback);
            }
          });
        });
      }),
    );
  }

  forgotPassword(username: string): Observable<void> {
    return this.getCognitoUser(username).pipe(
      mergeMap(
        (user) => new Observable<void>((subscriber) => {
          user.forgotPassword(this.resultHandlerCallback(subscriber));
        }),
      ),
    );
  }

  confirmNewPassword(
    username: string,
    newPassword: string,
    verificationCode: string,
  ): Observable<void> {
    return this.getCognitoUser(username).pipe(
      mergeMap(
        (user) => new Observable<void>((subscriber) => {
          user.confirmPassword(
            verificationCode,
            newPassword,
            this.resultHandlerCallback(subscriber),
          );
        }),
      ),
    );
  }

  isLoggedIn(): Observable<boolean> {
    return this.poolService
      .getSession()
      .pipe(map((session) => Boolean(session?.getAccessToken().getJwtToken())));
  }

  private configure({
    clients,
    userPoolId,
  }: DonorIdentityAuthClientResponseDto): void {
    const ClientId = Object.keys(clients)[0];
    const UserPoolId = userPoolId;

    this.poolService.setUserPool(
      new CognitoUserPool({
        ClientId,
        UserPoolId,
      }),
    );
  }

  private getCognitoUser(username: string): Observable<CognitoUser> {
    return this.poolService.getUserPool().pipe(
      map(
        (userPool) => new CognitoUser({
          Username: username,
          Pool: userPool,
        }),
      ),
    );
  }

  private resultHandlerCallback(subscriber: Subscriber<void>): {
    onSuccess: () => void;
    onFailure: (err: Error) => void;
  } {
    return {
      onSuccess: () => {
        subscriber.next();
        subscriber.complete();
      },
      onFailure: (err: Error) => {
        subscriber.error(err);
        subscriber.complete();
      },
    };
  }

  private errorCallback(subscriber: Subscriber<void>): (err?: Error) => void {
    return (err?: Error) => {
      if (err) {
        subscriber.error(err);
      } else {
        subscriber.next();
      }
      subscriber.complete();
    };
  }
}
