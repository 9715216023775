import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function noWhiteSpaceValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let validationResult: ValidationErrors | null = null;
    if (control instanceof FormControl) {
      const controlValue = String(control.value ?? '');
      if (controlValue.length && controlValue.trim().length !== control.value.length) {
        validationResult = { whitespace: true };
      }
    }
    return validationResult;
  };
}
