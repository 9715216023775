<div class="dialog-container">
  <div>
    <div>
      <button class="close" mat-button (click)="close()">
        <mat-dialog-content>
        X
        </mat-dialog-content>
      </button>
    </div>
  </div>
    <mat-dialog-content>
      {{ 'account.exists.text' | translate | sanitize }}
    </mat-dialog-content>
    <div class="flow-container">
      @if (provider === 'Google'){
        <hae-google-btn  mat-raised-button mat-dialog-close [federatedLogin]="federatedLogin"/>
      }@else if(provider === 'SignInWithApple'){
        <hae-apple-btn mat-raised-button mat-dialog-close [federatedLogin]="federatedLogin"/>
      }@else if(provider === 'Facebook'){
        <hae-facebook-btn mat-raised-button mat-dialog-close [federatedLogin]="federatedLogin"/>
      }@else{
        @if ( tenantConfig$ | async; as tenantConfig) {
          @if (provider === tenantConfig.openIDLogin.toString()){
            <button
              (click)="federatedLogin(tenantConfig.openIDLogin.toString())"
              class="social-login"
              >
              <span class="button-text">{{
                "loginPage.openIDLogin" | translate
              }}</span>
            </button>
          }@else if (provider === tenantConfig.SAMLLogin.toString()){
            <button
              (click)="federatedLogin(tenantConfig.SAMLLogin.toString())"
              class="social-login"
              >
              <span class="button-text">{{ "loginPage.SAMLLogin" | translate }}</span>
            </button>
          }
        }
      }
    </div>
</div>
