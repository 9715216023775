import { AsyncPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@hae/api';
import { CognitoService, ConfirmationStatus, MfaService, SentCodeStatus } from '@hae/auth';
import { TenantStateService } from '@hae/state';
import { DialogComponent } from '@hae/utils';
import { TranslateModule } from '@ngx-translate/core';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import {
  catchError,
  merge,
  mergeMap, of,
  Subject,
  take,
  zip,
} from 'rxjs';

import { FormErrorComponent } from '../../../../../utils/src/lib/components/form-error/form-error.component';
import { FormFieldComponent } from '../../../../../utils/src/lib/components/form-field/form-field.component';
import { LoadingButtonComponent } from '../../../../../utils/src/lib/components/loading-button/loading-button.component';
import { OAuthAbstractService } from '../../services/oauth/oauth-abstract.service';
import { AppleBtnComponent } from '../apple-button/apple-button.component';
import { CodeVerificationComponent } from '../code-verification/code-verification.component';
import { FacebookBtnComponent } from '../facebook-button/facebook-button.component';
import { GoogleBtnComponent } from '../google-button/google-button.component';
import { OauthDialogComponent } from '../oauth-dialog/oauth-dialog.component';

export enum AuthUIState {
  LOGIN,
  REGISTRATION,
  RESET_PASSWORD,
  VERIFY_EMAIL,
  VERIFY_SMS,
  NEW_PASSWORD,
  CONFIRM_SMS,
  CODE_VERIFICATION
}

@Component({
  selector: 'hae-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  standalone: true,
  imports: [
    MatDividerModule,
    ReactiveFormsModule,
    FormFieldComponent,
    MatFormFieldModule,
    FormErrorComponent,
    LoadingButtonComponent,
    MatButtonModule,
    MatIconModule,
    AsyncPipe,
    TranslateModule,
    GoogleBtnComponent,
    AppleBtnComponent,
    FacebookBtnComponent,
    CodeVerificationComponent,
  ],
})
export class AuthComponent implements OnInit {
  @Input() oAuthService!: OAuthAbstractService;

  @Output() loggedIn = new EventEmitter<string | undefined>();

  @Output() registered = new EventEmitter<
    ReturnType<typeof this.registrationForm.getRawValue>
  >();

  public federatedLogin = (loginProvider: string) => {
    this.oAuthService.login(loginProvider);
  };

  $authUiState = signal<AuthUIState>(AuthUIState.LOGIN);

  loginForm = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  registrationForm = this.fb.group({
    username: ['', [Validators.required]],
    email: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  resetPasswordForm = this.fb.group({ email: ['', [Validators.required]] });

  verificationInput = '';

  codeVerificationForm = this.fb.group({
    input: [
      {
        value: '',
        disabled: true,
      },
      [Validators.required],
    ],
    code: ['', [Validators.required]],
    password: '',
    username: '',
  });

  newPasswordForm = this.fb.group({
    email: {
      value: '',
      disabled: true,
    },
    newPassword: ['', [Validators.required]],
    code: ['', [Validators.required]],
    deliveryMethod: {
      value: '',
      disabled: true,
    },
  });

  confirmSmsForm = this.fb.group({
    phone: [
      {
        value: '',
        disabled: true,
      },
    ],
  });

  $loading = signal(false);

  config$ = this.tenantStateService.getConfiguration();

  passwordResetState = new Subject<string>();

  confirmResetState = new Subject<{
    code:string;
    newPassword:string;
  }>();

  isPasswordResetCodeInvalid = signal(false);

  AuthUIState = AuthUIState;

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: NonNullableFormBuilder,
    private cognitoService: CognitoService,
    private tenantStateService: TenantStateService,
    private dialog: MatDialog,
    private userService: UserService,
    private mfaService:MfaService,
  ) {}

  ngOnInit(): void {
    this.oAuthService.setSSOAction(() => {
      this.startMfaVerification();
    });

    const { register } = this.activatedRoute.snapshot.queryParams;
    if (register) {
      this.changeAuthUIState(AuthUIState.REGISTRATION);
    }

    this.cognitoService.isLoggedIn().subscribe(
      (isLoggedIn) => {
        if (isLoggedIn) {
          this.$loading.set(true);
          this.startMfaVerification();
        }
      },
    );

    merge(
      this.passwordResetState,
      this.confirmResetState,
    ).subscribe(
      (val) => {
        val.valueOf();
      },
    );

    // setup password reset flow
    zip(this.passwordResetState, this.confirmResetState).subscribe(
      ([confirmationId, resetState]) => {
        this.mfaService.verifyPasswordReset(confirmationId, resetState.code, resetState.newPassword).pipe(
          catchError(
            (err) => of(err),
          ),
        ).subscribe(
          (res) => {
            if (res) {
              this.displayConfirmationError(res.error.error.code);
              this.isPasswordResetCodeInvalid.set(true);
            } else {
              this.changeAuthUIState(AuthUIState.LOGIN);
              this.dialog.open(
                DialogComponent,
                {
                  data: {
                    title: 'auth.password.change.success.title',
                    text: 'auth.password.change.success.text',
                  },
                },
              );
            }
            this.$loading.set(false);
          },
        );
      },
    );
  }

  private parseCognitoPasswordError(err:string) {
    const errArr = err.slice(0, err.indexOf('(')).split(':');
    return errArr[errArr.length - 1];
  }

  private checkIfUserIsOauth(username:string) {
    if (!/^(.+)@(\S+)$/.test(username)) {
      return of('user not found');
    }
    return this.userService.getUserIdentityProviderInfo(username).pipe(
      catchError((err:HttpErrorResponse) => {
        if (err.status === 404) {
          return of(undefined);
        }
        this.dialog.open(DialogComponent, {
          data: {
            title: 'auth.error.fetching.user.title',
            text: 'auth.error.fetching.user.text',
          },
        });
        this.$loading.set(false);
        return of();
      }),
      mergeMap((userIdent) => {
        if (userIdent !== undefined && userIdent.payload.isThirdPartyProvider) {
          // user is authenticated through oauth
          this.dialog.open(OauthDialogComponent, {
            data: {
              federatedLogin: this.federatedLogin,
              provider: userIdent.payload.providerName,
              tenantConfig: this.config$,
            },
          });
          this.$loading.set(false);
          return of('found user');
        }
        this.$loading.set(false);
        return of('user not found');
      }),
    );
  }

  onFocusOut() {
    const { username } = this.loginForm.getRawValue();
    this.checkIfUserIsOauth(username).subscribe();
  }

  private startMfaVerification() {
    this.$loading.set(true);
    this.mfaService.startConfirmation().subscribe(
      (sentCodeStatus) => {
        if (sentCodeStatus.sentStatus === SentCodeStatus.CONFIRMED || sentCodeStatus.sentStatus === SentCodeStatus.NOT_A_DONOR) {
          this.loggedIn.emit();
        } else if (sentCodeStatus.sentStatus === SentCodeStatus.STARTED_CONFIRMATION_MAIL) {
          if (sentCodeStatus.email !== undefined) {
            this.startCodeVerification('MAIL', sentCodeStatus.email);
          } else {
            this.$loading.set(false);
            throw Error('This should not be possible, user is missing their email for email verification.');
          }
        } else if (sentCodeStatus.sentStatus === SentCodeStatus.STARTED_CONFIRMATION_SMS) {
          if (sentCodeStatus.phone !== undefined) {
            this.startCodeVerification('SMS', sentCodeStatus.phone);
          } else {
            this.$loading.set(false);
            throw Error('This should not be possible, user is missing their phone number for sms verification.');
          }
        } else {
          // either NO_STATUS or ERROR_DURING_SEND
          this.dialog.open(
            DialogComponent,
            {
              data: {
                title: 'auth.error.code.not.sent.title',
                text: 'auth.error.code.not.sent.text',
              },
            },
          );
        }
        this.$loading.set(false);
      },
    );
  }

  login(): void {
    this.mfaService.skipEmailVerification.set(false);
    this.$loading.set(true);
    const { username, password } = this.loginForm.getRawValue();

    this.checkIfUserIsOauth(username).subscribe(
      (res) => {
        if (res === 'user not found') {
          // user is not logged in via oauth
          this.cognitoService.authenticate(username, password).subscribe({
            next: () => {
              this.startMfaVerification();
            },
            error: (error) => {
              this.$loading.set(false);
              if (error.message === 'User is not confirmed.') {
                this.verificationInput = username;
                this.codeVerificationForm.patchValue({
                  input: username,
                  password,
                  username,
                });

                this.resendCognitoCode();
                this.changeAuthUIState(AuthUIState.CODE_VERIFICATION);
                return;
              }
              this.showError(error);
            },
          });
        }
      },
    );
  }

  register(): void {
    this.$loading.set(true);

    const { email, username, password } = this.registrationForm.getRawValue();

    this.tenantStateService
      .getName()
      .pipe(
        mergeMap((name) => this.cognitoService.registerUser({
          email: username,
          password,
          customAttributes: [
            new CognitoUserAttribute({
              Name: 'email',
              Value: email,
            }),
            new CognitoUserAttribute({
              Name: 'custom:tenantName',
              Value: name,
            }),
          ],
        })),
      )
      .subscribe({
        next: () => {
          this.verificationInput = email;
          this.registered.emit(this.registrationForm.getRawValue());
          this.codeVerificationForm.patchValue({
            input: email,
            password,
            username,
          });
          this.changeAuthUIState(AuthUIState.CODE_VERIFICATION);
          this.$loading.set(false);
        },
        error: (error) => {
          this.showError(error);
          this.$loading.set(false);
        },
      });
  }

  resetPassword(): void {
    this.$loading.set(true);

    const { email } = this.resetPasswordForm.getRawValue();

    this.cognitoService.forgotPassword(email).subscribe({
      next: () => {
        this.newPasswordForm.reset({ email });
        this.changeAuthUIState(AuthUIState.NEW_PASSWORD);
        this.$loading.set(false);
      },
      error: (error) => {
        this.showError(error);
        this.$loading.set(false);
      },
    });
  }

  private convertCognitoError(cognitoError:string) {
    if (cognitoError.includes('Password not long enough')) {
      return 'auth.password.short.error';
    } if (cognitoError.includes('Password must have lowercase characters')) {
      return 'auth.password.lowercase.error';
    } if (cognitoError.includes('Password must have numeric characters')) {
      return 'auth.password.numeric.error';
    } if (cognitoError.includes('Password must have uppercase characters')) {
      return 'auth.password.uppercase.error';
    }
    return 'auth.password.format.error';
  }

  private displayConfirmationError(errMsg:string) {
    if (errMsg === 'Already confirmed') {
      this.dialog.open(DialogComponent, {
        data: {
          title: 'dialog.codeConfirm.error.alreadyConfirmed.title',
          text: 'dialog.codeConfirm.error.alreadyConfirmed.text',
        },
      });
    } else if (errMsg === 'Expired code') {
      this.dialog.open(DialogComponent, {
        data: {
          title: 'dialog.codeConfirm.error.expiredCode.title',
          text: 'dialog.codeConfirm.error.expireCode.text',
        },
      });
    } else if (errMsg === 'Invalid code' || errMsg.includes('Wrong code format')) {
      this.dialog.open(DialogComponent, {
        data: {
          title: 'dialog.codeConfirm.error.invalidCode.title',
          text: 'dialog.codeConfirm.error.invalidCode.text',
        },
      });
    } else if (errMsg.includes('CognitoIdentityProvider')) {
      this.dialog.open(DialogComponent, {
        data: {
          title: 'auth.password.error',
          text: this.convertCognitoError(this.parseCognitoPasswordError(errMsg)),
        },
      });
    } else {
      this.dialog.open(DialogComponent, {
        data: {
          title: 'dialog.codeConfirm.error.title',
          text: 'dialog.codeConfirm.error.text',
        },
      });
    }
  }

  private verifyCodeViaCognito(username:string, password:string, code:string) {
    this.cognitoService
      .confirmRegistration(username, code)
      .pipe(mergeMap(() => this.cognitoService.authenticate(username, password)))
      .subscribe({
        next: () => {
          this.startMfaVerification();
        },
        error: (error) => {
          this.showError(error);
          this.$loading.set(false);
        },
      });
  }

  verifyCode(form:{ deliveryMethod?: 'MAIL'|'SMS', code: string }): void {
    this.$loading.set(true);
    if (this.$authUiState() === AuthUIState.CODE_VERIFICATION) {
      const { username, password } = this.codeVerificationForm.getRawValue();
      this.verifyCodeViaCognito(username, password, form.code);
    } else {
      if (!form.deliveryMethod) {
        throw Error('This should not be possible');
      }
      this.mfaService.confirmCode(form.code, form.deliveryMethod).pipe(
        catchError(() => {
          this.$loading.set(false);
          return of();
        }),
      ).subscribe(
        (confirmationStatus) => {
          if (confirmationStatus.status === ConfirmationStatus.CONFIRMED) {
            this.startMfaVerification();
          } else if (confirmationStatus.error) {
          // must be an error
            this.displayConfirmationError(confirmationStatus.error);
            this.$loading.set(false);
          }
        },
      );
    }
  }

  setNewPassword(): void {
    if (this.isPasswordResetCodeInvalid()) {
      this.dialog.open(DialogComponent, {
        data: {
          title: 'dialog.codeConfirm.error.invalidExpiredCode.title',
          text: 'dialog.codeConfirm.error.invalidExpiredCode.text',
        },
      });
    } else {
      this.$loading.set(true);
      const { newPassword, code } = this.newPasswordForm.getRawValue();
      this.confirmResetState.next({
        code,
        newPassword,
      });
    }
  }

  startCodeVerification(deliveryMethod: 'MAIL' | 'SMS', input: string) {
    this.$loading.set(false);
    this.verificationInput = input;
    if (deliveryMethod === 'MAIL') {
      this.changeAuthUIState(AuthUIState.VERIFY_EMAIL);
    } else {
      this.changeAuthUIState(AuthUIState.VERIFY_SMS);
    }

    this.codeVerificationForm.patchValue({ input });
  }

  changeAuthUIState(newState: AuthUIState): void {
    if (newState === AuthUIState.RESET_PASSWORD) {
      this.resetPasswordForm.reset();
    }
    if ([AuthUIState.LOGIN, AuthUIState.REGISTRATION, AuthUIState.RESET_PASSWORD].some((val) => val === newState)) {
      this.mfaService.skipEmailVerification.set(false);
    }
    this.$authUiState.set(newState);
  }

  private showError(error: Error): void {
    this.dialog.open(DialogComponent, {
      data: {
        title: 'Authentication Error',
        text: error.message,
      },
    });
  }

  showPassword(password: HTMLInputElement) {
    // eslint-disable-next-line no-param-reassign
    password.type = password.type === 'text' ? 'password' : 'text';
  }

  // skip email verification show the user their phone number
  smsConfirmed() {
    this.$loading.set(true);
    this.mfaService.getPhone().pipe(
      take(1),
      catchError(() => {
        this.$loading.set(false);
        return of();
      }),
    ).subscribe(
      (phone) => {
        this.$loading.set(false);
        this.changeAuthUIState(AuthUIState.CONFIRM_SMS);
        this.confirmSmsForm.patchValue({ phone });
        this.mfaService.skipEmailVerification.set(true);
      },
    );
  }

  startSmsVerification():void {
    this.$loading.set(true);
    this.mfaService.getPhone().subscribe(
      (phone) => {
        this.mfaService.sendCodeSmsNoVerification().subscribe(
          (confirmationData) => {
            if (confirmationData.error) {
              this.dialog.open(DialogComponent, {
                data: {
                  title: 'dialog.confirmation.error.title',
                  text: 'dialog.confirmation.error.text',
                },
              });
              this.$loading.set(false);
            } else {
              this.startCodeVerification('SMS', phone);
              this.$loading.set(false);
            }
          },
        );
      },
    );
  }

  showUpdatePhonePopUp() {
    this.dialog.open(DialogComponent, {
      data: {
        title: 'dialog.confirmation.update.phone.on.center.title',
        text: 'dialog.confirmation.update.phone.on.center.text',
      },
    });
  }

  resendCognitoCode(): void {
    this.$loading.set(true);

    const { input, username } = this.codeVerificationForm.getRawValue();

    this.cognitoService.resendCode(username || input).subscribe({
      next: () => {
        this.dialog.open(DialogComponent, {
          data: {
            title: 'dialog.verificationCodeResent.title',
            text: 'dialog.verificationCodeResent.text',
          },
        });
        this.$loading.set(false);
      },
      error: (error) => {
        this.$loading.set(false);
        this.showError(error);
      },
    });
  }

  forgotEmailUsername(): void {
    let ref:MatDialogRef<DialogComponent> | null = null;
    ref = this.dialog.open(DialogComponent, {
      data: {
        title: 'auth.forgot_username_email.title',
        text: 'auth.forgot_username_email.text',
        primaryLabel: 'auth.create_account',
        primaryCallback: () => {
          if (ref) {
            this.changeAuthUIState(AuthUIState.REGISTRATION);
            ref.close();
          }
        },
      },
    });
  }

  initiatePasswordReset(deliveryMethod: 'SMS'|'MAIL') {
    const { email } = this.resetPasswordForm.getRawValue();
    this.checkIfUserIsOauth(email).subscribe(
      (isUserOauth) => {
        if (isUserOauth === 'user not found') {
          this.userService.initiatePasswordReset(email, deliveryMethod).subscribe(
            (res) => {
              this.isPasswordResetCodeInvalid.set(false);
              this.passwordResetState.next(res.payload.userConfirmationId);
              this.newPasswordForm.patchValue(
                { email, deliveryMethod },
              );
              this.changeAuthUIState(AuthUIState.NEW_PASSWORD);
            },
          );
        }
      },
    );
  }

  reinitiatePasswordReset() {
    // Write code to verify email here
    const { email, deliveryMethod } = this.newPasswordForm.getRawValue();
    if (deliveryMethod !== 'SMS' && deliveryMethod !== 'MAIL') {
      throw Error('this should not be possible, user did not select a delivery method on the password reset form.');
    } else {
      this.userService.initiatePasswordReset(email, deliveryMethod).subscribe(
        (res) => {
          this.isPasswordResetCodeInvalid.set(false);
          this.passwordResetState.next(res.payload.userConfirmationId);
        },
      );
    }
  }
}
