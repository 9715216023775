export class CurrentLangSwitched {
  static readonly type = '[I18n] Current Language Switched';

  constructor(public payload: string) {}
}

export class SupportedLanguagesLoadedSuccessfully {
  static readonly type = '[I18n] Supported Languages Loaded Successfully';

  constructor(public payload: string[]) {}
}

export class LanguageSwitcherDisabled {
  static readonly type = '[I18n] The language switcher was disabled';
}

export class LanguageSwitcherEnabled {
  static readonly type = '[I18n] The language switcher was enabled';
}

export class SetPrevLang {
  static readonly type = '[I18n] Set Previous Language';
}

export class SwitchToPrevLang {
  static readonly type = '[I18n] Current Language Switched to previous';
}
