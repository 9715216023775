export function simpleDateFormat(date: Date) {
  let birthMonth = `${date.getMonth() + 1}`;
  let birthDay = `${date.getDate()}`;
  const birthYear = date.getFullYear();

  if (birthMonth.length < 2) {
    birthMonth = `0${birthMonth}`;
  }
  if (birthDay.length < 2) {
    birthDay = `0${birthDay}`;
  }

  return [birthYear, birthMonth, birthDay].join('-');
}
