import { AsyncPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserPreferencesDto, UserService } from '@hae/api';
import { TenantStateService } from '@hae/state';
import { DialogComponent } from '@hae/utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'hae-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss'],
  standalone: true,
  imports: [
    MatCheckboxModule,
    AsyncPipe,
    MatProgressSpinnerModule,
    TranslateModule,
  ],
})
export class PreferencesComponent implements OnInit {
  preferences = new BehaviorSubject<UserPreferencesDto | undefined>(undefined);

  constructor(
    private userService: UserService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    public tenantState: TenantStateService,
  ) {}

  ngOnInit(): void {
    this.userService
      .getUserPreferences()
      .pipe(
        map((preferencesResponse) => {
          if (preferencesResponse) {
            return preferencesResponse;
          }

          return {
            receiveCalls: false,
            receiveEmails: false,
            pushNotifications: false,
            receiveSms: false,
            langPreference: this.translateService.currentLang,
          };
        }),
      )
      .subscribe((preferences) => {
        this.preferences.next(preferences);
      });
  }

  changePreferences(preference: MatCheckboxChange): void {
    const preferenceKey = [preference.source.name] as unknown as string;
    const newPreference = {
      ...(this.preferences.value as UserPreferencesDto),
      [preferenceKey]: preference.checked,
    };

    this.preferences.next(undefined);

    this.userService
      .updateUserPreferences(newPreference)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          if (
            errorResponse.status === 400
            && errorResponse.error.error.code === 'No user preferences found'
          ) {
            return this.userService.createUserPreferences(newPreference);
          }

          return of(undefined);
        }),
      )
      .subscribe((preferencesResponse) => {
        if (preferencesResponse) {
          this.preferences.next(preferencesResponse);
          return;
        }

        this.dialog.open(DialogComponent, {
          data: {
            title: 'preferences.errorTitle',
            text: 'preferences.errorDescription',
          },
        });
      });
  }
}
