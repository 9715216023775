<div class="preferences-container">
  <h1>{{ "preferences.title" | translate }}</h1>
  <h2>{{ "preferences.communications" | translate }}</h2>
  @if (tenantState.getName() | async; as tenantName) {
    @if (preferences | async; as preferences) {
      <div class="preference-container">
        <mat-checkbox
          (change)="changePreferences($event)"
          [checked]="preferences.receiveEmails"
          color="primary"
          name="receiveEmails"
          >
          <span>
            {{ "preferences.email" | translate : { tenantName: tenantName } }}
          </span>
        </mat-checkbox>
      </div>
      <div class="preference-container">
        <mat-checkbox
          (change)="changePreferences($event)"
          [checked]="preferences.receiveSms"
          color="primary"
          name="receiveSms"
          >
          <span>
            {{ "preferences.sms" | translate : { tenantName: tenantName } }}
          </span>
        </mat-checkbox>
      </div>
      <div class="preference-container">
        <mat-checkbox
          (change)="changePreferences($event)"
          [checked]="preferences.receiveCalls"
          color="primary"
          name="receiveCalls"
          ><span>
          {{ "preferences.calls" | translate : { tenantName: tenantName } }}
        </span>
      </mat-checkbox>
    </div>
  } @else {
    <mat-spinner color="primary" diameter="30"></mat-spinner>
  }
}
</div>
