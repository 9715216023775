import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fallbackDate', standalone: true })
export class FallbackDatePipe extends DatePipe implements PipeTransform {
  transform(
    value: Date | string | number | null | undefined,
    format?: string,
    timezone?: string,
    locale?: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any {
    try {
      // DatePipe will throw an exception if the locale isn't registered in the root module
      //  since Jumpstart's languages can come from the interfacing BECS we need to fallback should this occur
      return super.transform(value, format, timezone, locale);
      // eslint-disable-next-line no-empty
    } catch {}

    return super.transform(value, format, timezone, 'en-US');
  }
}
