import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PoolService } from '@hae/auth';
import { mergeMap, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private poolService: PoolService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (
      request.method === 'GET'
      && [
        'donor-identity/api/tenants/auth/clients',
        'donor-identity/api/auth/store',
        'donor-identity/api/info/languages',
        'tenant-service/api/resources/i18n',
        'tenant-service/api/resources/media/CSS_STYLES',
        'tenant-service/api/configuration/tenant/public',
      ].some((url) => request.url.includes(url))
    ) {
      return next.handle(request);
    }

    return this.poolService.getSession().pipe(
      map((session) => session?.getAccessToken().getJwtToken()),
      mergeMap((jwt) => next.handle(
        jwt
          ? request.clone({ setHeaders: { Authorization: `Bearer ${jwt}` } })
          : request,
      )),
    );
  }
}
