export class QRData {
  public static verbose = false;

  public static pack(data: object): string {
    return JSON.stringify(data);
  }

  public static unpack<T>(packedString: string): T {
    return JSON.parse(packedString);
  }
}
