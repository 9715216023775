import { Injectable } from '@angular/core';

const forScope = (key: string, scope: string) => (scope ? `${scope}.${key}` : key);

@Injectable({ providedIn: 'root' })
export class MetaService {
  public get<T>(key: string, scope = ''): T | null {
    const item = localStorage.getItem(forScope(key, scope));
    if (item) {
      return JSON.parse(item);
    }
    return null;
  }

  public set<T>(key: string, value: T, scope = ''): void {
    localStorage.setItem(forScope(key, scope), JSON.stringify(value));
  }

  public remove(key: string, scope = ''): void {
    localStorage.removeItem(forScope(key, scope));
  }

  public purge(): void {
    localStorage.clear();
  }
}
