<div class="dialog-container">
  <h2 mat-dialog-title>{{ data.title | translate }}</h2>
  @if (data.text) {
    <mat-dialog-content
      [innerHTML]="data.text | translate | sanitize"
      >
    </mat-dialog-content>
  }
  @if (data.template) {
    <ng-container
      [ngTemplateOutlet]="data.template"
    ></ng-container>
  }
  <mat-dialog-actions>
    @if (!data.hideButton) {
      @if (data.primaryCallback) {
        <button
          mat-raised-button
          (click)="data.primaryCallback()"
          color="primary"
          >
          <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
        </button>
      } @else {
        <button mat-raised-button mat-dialog-close color="primary">
          <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
        </button>
      }
      <ng-template #buttonContent>
        @if (data.primaryLabel) {
          {{ data.primaryLabel | translate }}
        } @else {
          {{ "dialog.ok" | translate }}
        }
      </ng-template>
    }
    @if (data.secondaryLabel) {
      <a
        mat-dialog-close
        (click)="data.secondaryCallback && data.secondaryCallback()"
        class="hyperlink-text"
        >
        {{ data.secondaryLabel | translate }}
      </a>
    }
    @if (data.errorCode) {
      <div
        class="error-code"
        [class.has-cancel]="data.secondaryLabel"
        >
        {{ "dialog.errorCode" | translate }} [{{ data.errorCode }}]
      </div>
    }
  </mat-dialog-actions>
</div>
