export class TaskLoadingStarted {
  static readonly type = '[Page] Task Loading Started';
}

export class TaskLoadedSuccessfully {
  static readonly type = '[Page] Task Loaded Successfully';
}

export class PageLoadedSuccessfully {
  static readonly type = '[Page] Page Loaded Successfully';
}

export class UserTransferStart {
  static readonly type = '[Page] User Transfer Start';
}

export class UserTransferFinished {
  static readonly type = '[Page] User Transfer Finished';
}
