import { Injectable } from '@angular/core';
import { InformationControllerService } from '@hae/api';
import { TranslateService } from '@ngx-translate/core';
import {
  Action,
  createSelector,
  NgxsOnInit,
  State,
  StateContext,
} from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { map, Observable } from 'rxjs';

import { DEFAULT_LANGUAGE, I18N_STATE_TOKEN } from '../constants';
import {
  CurrentLangSwitched,
  LanguageSwitcherDisabled,
  LanguageSwitcherEnabled,
  SetPrevLang,
  SupportedLanguagesLoadedSuccessfully,
  SwitchToPrevLang,
} from './i18n.actions';
import { I18nState as StateInterface } from './index.d';

@State({
  name: I18N_STATE_TOKEN,
  defaults: {
    currentLanguage: DEFAULT_LANGUAGE,
    prevLanguage: null,
    supportedLanguages: [DEFAULT_LANGUAGE],
    isLanguageSwitcherDisabled: false,
  },
})
@Injectable()
export class I18nState implements NgxsOnInit {
  constructor(
    private informationControllerService: InformationControllerService,
    private translateService: TranslateService,
  ) {}

  ngxsOnInit({ dispatch }: StateContext<StateInterface>): void {
    this.informationControllerService
      .getBecsLanguageInfo()
      .subscribe((supportedLanguages) => {
        dispatch(new SupportedLanguagesLoadedSuccessfully(supportedLanguages));
      });
  }

  @Action(CurrentLangSwitched)
  currentLangSwitched(
    { setState }: StateContext<StateInterface>,
    action: CurrentLangSwitched,
  ): Observable<StateInterface> | void {
    return this.translateService
      .use(action.payload)
      .pipe(
        map(() => setState(patch<StateInterface>({ currentLanguage: action.payload }))),
      );
  }

  @Action(SetPrevLang)
  setPrevLang({ getState, setState }: StateContext<StateInterface>): void {
    const { currentLanguage } = getState();
    setState(patch<StateInterface>({ prevLanguage: currentLanguage }));
  }

  @Action(SwitchToPrevLang)
  switchToPrevLang({
    getState,
    setState,
  }: StateContext<StateInterface>): Observable<StateInterface> | undefined {
    const { prevLanguage } = getState();
    if (prevLanguage !== null) {
      return this.translateService.use(prevLanguage).pipe(
        map(() => setState(
          patch<StateInterface>({
            currentLanguage: prevLanguage,
            prevLanguage: null,
          }),
        )),
      );
    }
    return undefined;
  }

  @Action(SupportedLanguagesLoadedSuccessfully)
  supportedLanguagesLoadedSuccessfully(
    { getState, setState }: StateContext<StateInterface>,
    action: SupportedLanguagesLoadedSuccessfully,
  ): void {
    const { currentLanguage } = getState();
    this.translateService.use(currentLanguage);

    setState(patch<StateInterface>({ supportedLanguages: action.payload }));
  }

  @Action(LanguageSwitcherDisabled)
  languageSwitcherDisabled({ setState }: StateContext<StateInterface>): void {
    setState(patch<StateInterface>({ isLanguageSwitcherDisabled: true }));
  }

  @Action(LanguageSwitcherEnabled)
  languageSwitcherEnabled({ setState }: StateContext<StateInterface>): void {
    setState(patch<StateInterface>({ isLanguageSwitcherDisabled: false }));
  }
}

export class I18nStateSelectors {
  static currentLanguage: (state: StateInterface) => string = createSelector(
    [I18nState],
    (state: StateInterface) => state.currentLanguage,
  );

  static supportedLanguages = createSelector(
    [I18nState],
    (state: StateInterface) => state.supportedLanguages,
  );

  static isLanguageSwitcherDisabled = createSelector(
    [I18nState],
    (state: StateInterface) => state.isLanguageSwitcherDisabled,
  );
}
