/* eslint-disable max-classes-per-file */
import {
  DonorLookupRequestDto,
  DonorLookupResultResponseDto,
  ProfileResponseDto,
} from '@hae/api';

export class UserLogoutRequested {
  constructor(public global?: boolean) {}

  static readonly type = '[Auth] User Logout Requested';
}

export class UserLoggedSuccessfully {
  static readonly type = '[Auth] User Logged Successfully';
}

export class UserTransfer {
  static readonly type = '[Auth] User Transfer';

  constructor(public payload: { transferToken: string }) {}
}

export class UserProfileLoadedSuccessfully {
  static readonly type = '[Auth] User Profile Loaded Successfully';

  constructor(public payload: ProfileResponseDto) {}
}

export class UserLookupLoadedSuccessfully {
  static readonly type = '[Auth] User Lookup Loaded Successfully';

  constructor(public payload: DonorLookupResultResponseDto | false) {}
}

export class SaveUserDonorLookupData {
  static readonly type = '[Auth] Saving user donor lookup data';

  constructor(public payload: DonorLookupRequestDto) {}
}

export class UpdateDonorLookupLanguage {
  static readonly type = '[Auth] Update donor lookup language';

  constructor(public payload: string) {}
}

export class UpdateBiometricsAcknowledgement {
  static readonly type = '[Auth] Update Biometrics Acknowledgement';

  constructor(public payload: boolean) {}
}

export class UpdateReferralCode {
  static readonly type = '[Auth] Update Referral Code';

  constructor(public payload: string) {}
}

export class UserTransferInQuestionnaire {
  static readonly type = '[Auth] User Transfer In Questionnaire';
}
