<h1>
  @if(deliveryMethod == 'MAIL'){
      {{ "auth.confirm_mail_title" | translate }}
    }@else{
      {{ "auth.confirm_sms_title" | translate }}
    }
</h1>
<div class="description">
  @if(deliveryMethod == 'MAIL'){
      {{ "auth.confirm_mail_description" | translate }}
    }@else{
      {{ "auth.confirm_sms_description" | translate }}
    }
</div>
<form (ngSubmit)="emitData()" [formGroup]="codeVerificationForm">
  <hae-form-field>
    @if(deliveryMethod == 'MAIL'){
      <input
        class="input-amplify"
        formControlName="input"
        placeholder="{{ 'auth.email' | translate }}"
        type="text"
        />
    }@else{
      <input
        class="input-amplify"
        formControlName="input"
        placeholder="{{ 'auth.sms' | translate }}"
        type="text"
        />
    }
    <hae-form-error control="input">{{
      "login.form.fieldRequired" | translate
    }}</hae-form-error>
  </hae-form-field>
  <hae-form-field>
    <input
      class="input-amplify"
      formControlName="code"
      placeholder="{{ 'auth.confirmation_code' | translate }}"
      numeric
      />
    <hae-form-error control="code">{{
      "login.form.fieldRequired" | translate
    }}</hae-form-error>
  </hae-form-field>
  <span>
    {{ "auth.lost_code" | translate }}
    <span (click)="resendVerificationCode()" class="link">{{
      "auth.resend_code" | translate
    }}</span>
  </span>
  <hae-loading-button [loading]="$loading()">
    <button
      [disabled]="codeVerificationForm.invalid"
      class="accept-button"
      color="primary"
      mat-raised-button
      type="submit"
      >
      {{ "auth.send_code" | translate }}
    </button>
  </hae-loading-button>
</form>
