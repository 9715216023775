import { Injectable } from '@angular/core';
import { DonorIdentityAuthClientResponseDto } from '@hae/api';

import { OAuthAbstractService } from './oauth-abstract.service';

@Injectable({ providedIn: 'root' })
export class OAuthWebService extends OAuthAbstractService {
  protected init(auth: DonorIdentityAuthClientResponseDto): void {
    this.oauthService.configure(this.getConfig(auth));

    this.oauthService.tryLogin().then(() => this.doSSO());
  }
}
